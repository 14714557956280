import { clamp } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { PAYLOAD_MASS_KG_DEFAULT } from '@sb/routine-runner';
import { isApproximatelyEqual } from '@sb/utilities';
import {
  useIsAnotherSessionRunningAdHocCommand,
  useRobotRoutineRunningState,
} from '@sbrc/hooks';
import type { UseRoutineRunnerHandleArguments } from '@sbrc/hooks';

import type {
  OnRobot3FG15Command,
  OR3FG15GripKind,
  OnRobot3FG15State,
  OnRobot3FG15Configuration,
} from '../..';
import {
  calculateOR3FG15DiameterRangeWithConfig,
  calculateOR3FG15DefaultDiameterWithFingerAngle,
  getOR3FG15ConfigWithRoutineRunnerState,
  OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
  OR_3FG15_DIAMETER_METERS_SLIDER_STEP,
  OR_3FG15_FORCE_NEWTONS_SLIDER_STEP,
  OR_3FG15_TARGET_FORCE_DEFAULT,
} from '../..';

import calculateFingerAngleFromControlState from './calculateFingerAngleFromControlState';
import {
  OR_3FG15_COMMAND_DEFAULT,
  OR_3FG15_FORCE_RANGE,
  OR_3FG15_DIAMETER_DEFAULT,
} from './constants';

type GripperRange = {
  min: number;
  max: number;
};

type GripperConformState = 'outdated' | 'diverged' | 'updated';

type GripperControlStateOutput = {
  canApplyGripperChanges: boolean;
  changeCommandKind: (shouldGrip: boolean) => void;
  changeGripKind: (
    gripKind: OnRobot3FG15Command['gripKind'],
  ) => OnRobot3FG15Command;
  changeTargetDiameter: (action: React.SetStateAction<number>) => void;
  changeTargetForce: (action: React.SetStateAction<number> | undefined) => void;
  command: OnRobot3FG15Command;
  conformGripperControlStateToActualState: (state: OnRobot3FG15State) => void;
  diameterRange: GripperRange;
  forceRange: GripperRange;
  isDiameterEqual: boolean;
  routineRunnerDiameter: number;
  routineRunnerForce: number;
  routineRunnerGripKind: OnRobot3FG15Command['gripKind'];
  setConformedState: (formState: GripperConformState) => void;
  setTargetPayload: React.Dispatch<React.SetStateAction<number>>;
  targetDiameterMeters: number;
  targetFingerAngle: number;
  targetPayload: number;
  isGripChecked: boolean;
  status: {
    isBusy: boolean;
    isCalibrationOk: boolean;
    isConnected: boolean;
    isForceGripDetected: boolean;
    isGripDetected: boolean;
  };
};

interface UseGripperControlStateArguments
  extends UseRoutineRunnerHandleArguments {
  routineRunnerGripperState: OnRobot3FG15State;
  routineRunnerPayload: number | null;
}

const useGripperControlState = (
  args: UseGripperControlStateArguments,
): GripperControlStateOutput => {
  // Whether the form has been modified since load.
  // If not, updates to the gripper state should modify the form state.
  const { routineRunnerPayload, routineRunnerGripperState } = args;

  const actualPayloadValue = routineRunnerPayload ?? PAYLOAD_MASS_KG_DEFAULT;

  const [conformedState, setConformedState] =
    useState<GripperConformState>('outdated');

  const [targetPayload, setTargetPayload] = useState<number>(
    routineRunnerPayload ?? PAYLOAD_MASS_KG_DEFAULT,
  );

  const [command, setCommand] = useState<OnRobot3FG15Command>(
    OR_3FG15_COMMAND_DEFAULT,
  );

  const {
    gripKind,
    targetForce = OR_3FG15_TARGET_FORCE_DEFAULT,
    targetDiameter,
  } = command;

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    robotID: args.robotID,
  });

  const routineRunningState = useRobotRoutineRunningState({
    robotID: args.robotID,
  });

  const isRoutineRunning = routineRunningState !== null;

  const routineRunnerForce =
    routineRunnerGripperState?.targetForceNewtons ??
    (OR_3FG15_TARGET_FORCE_DEFAULT as number);

  const routineRunnerGripKind =
    routineRunnerGripperState?.gripKind ?? command.gripKind;

  const routineRunnerDiameter =
    routineRunnerGripperState?.diameterMeters ?? OR_3FG15_DIAMETER_DEFAULT;

  const fingerConfiguration: OnRobot3FG15Configuration =
    getOR3FG15ConfigWithRoutineRunnerState(routineRunnerGripperState);

  // discover current angle
  const targetFingerAngle: number = calculateFingerAngleFromControlState({
    command,
    config: fingerConfiguration,
  });

  const targetDiameterMeters: number = targetDiameter;

  const diameterRange: GripperRange = useMemo(() => {
    return calculateOR3FG15DiameterRangeWithConfig(
      fingerConfiguration,
      gripKind,
    );
  }, [gripKind, fingerConfiguration]);

  const changeGripKind = (
    newGripKind: OR3FG15GripKind,
  ): OnRobot3FG15Command => {
    setConformedState('diverged');

    // convert current angle to new diameter once we swap the grip kind
    const meters = calculateOR3FG15DefaultDiameterWithFingerAngle(
      fingerConfiguration,
      targetFingerAngle,
      newGripKind,
    );

    const newCommand: OnRobot3FG15Command = {
      ...command,
      gripKind: newGripKind,
      targetDiameter: meters,
    };

    setCommand(newCommand);

    return newCommand;
  };

  const changeCommandKind = (shouldGrip: boolean): void => {
    setConformedState('diverged');

    setCommand((prevState) => {
      return {
        ...prevState,
        waitForGripToContinue: shouldGrip,
        isFlexGrip: shouldGrip,
      };
    });
  };

  const changeTargetDiameter = (action: React.SetStateAction<number>) => {
    setConformedState('diverged');

    setCommand((previousState) => {
      const meters =
        typeof action === 'function'
          ? action(previousState.targetDiameter)
          : action;

      return {
        ...(previousState ?? {}),
        targetDiameter: clamp(
          meters ?? OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
          diameterRange.min,
          diameterRange.max,
        ),
      };
    });
  };

  const changeTargetForce = (
    action: React.SetStateAction<number> | undefined,
  ) => {
    setConformedState('diverged');

    setCommand((previousState) => {
      let newtons: number | undefined;

      if (typeof action === 'function') {
        newtons = action(
          previousState?.targetForce ?? OR_3FG15_TARGET_FORCE_DEFAULT,
        );
      } else {
        newtons = action;
      }

      return {
        ...previousState,
        targetForce: newtons
          ? clamp(newtons, OR_3FG15_FORCE_RANGE.min, OR_3FG15_FORCE_RANGE.max)
          : undefined,
      };
    });
  };

  /* Make the temporary form state conform to the actual observed state
   * after actuation completes.
   *
   * For example, if the command was "grip to 10cm" but the actuation
   * resulted in a grasp of an object with width 15cm, we want the gripper
   * control state to be updated to 15cm once the actuation is complete.
   *
   * Accomplish this by marking the form as outdated, so the `useEffect`
   * will reset it.
   */
  const conformGripperControlStateToActualState = useCallback(
    (gripperRoutineRunnerState: OnRobot3FG15State) => {
      if (!gripperRoutineRunnerState) return;

      setCommand((previousState) => {
        return {
          ...previousState,
          gripKind: gripperRoutineRunnerState.gripKind,
          targetDiameter: gripperRoutineRunnerState.diameterMeters,
          targetForce:
            gripperRoutineRunnerState.targetForceNewtons ??
            OR_3FG15_TARGET_FORCE_DEFAULT,
        };
      });

      setTargetPayload(actualPayloadValue);

      setConformedState('updated');
    },
    [actualPayloadValue],
  );

  useEffect(() => {
    if (conformedState !== 'diverged' && routineRunnerGripperState) {
      conformGripperControlStateToActualState(routineRunnerGripperState);
    }
  }, [
    conformGripperControlStateToActualState,
    conformedState,
    routineRunnerGripperState,
  ]);

  const isForceEqual = isApproximatelyEqual(
    routineRunnerForce,
    targetForce,
    OR_3FG15_FORCE_NEWTONS_SLIDER_STEP,
  );

  const targetDiameterToCompare =
    routineRunnerGripKind === command.gripKind
      ? targetDiameterMeters
      : calculateOR3FG15DefaultDiameterWithFingerAngle(
          fingerConfiguration,
          targetFingerAngle,
          routineRunnerGripKind,
        );

  const isDiameterEqual = isApproximatelyEqual(
    routineRunnerDiameter,
    targetDiameterToCompare,
    OR_3FG15_DIAMETER_METERS_SLIDER_STEP,
  );

  const canApplyGripperChanges =
    !isRoutineRunning &&
    !isAnotherSessionMovingRobot &&
    (!isDiameterEqual ||
      !isForceEqual ||
      routineRunnerGripKind !== command.gripKind ||
      routineRunnerPayload !== targetPayload);

  return {
    canApplyGripperChanges,
    changeCommandKind,
    changeGripKind,
    changeTargetDiameter,
    changeTargetForce,
    command,
    conformGripperControlStateToActualState,
    diameterRange,
    forceRange: OR_3FG15_FORCE_RANGE,
    isDiameterEqual,
    routineRunnerDiameter,
    routineRunnerForce,
    routineRunnerGripKind,
    setConformedState,
    setTargetPayload,
    targetDiameterMeters,
    targetFingerAngle,
    targetPayload,
    isGripChecked: command.waitForGripToContinue ?? false,
    status: {
      isBusy: routineRunnerGripperState.isBusy,
      isCalibrationOk: routineRunnerGripperState.isCalibrationOk,
      isConnected: routineRunnerGripperState.isConnected,
      isForceGripDetected: routineRunnerGripperState.isForceGripDetected,
      isGripDetected: routineRunnerGripperState.isGripDetected,
    },
  };
};

export default useGripperControlState;
