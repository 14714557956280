import { useState } from 'react';

import type { OnRobotDualQuickChangerCommand } from '@sb/integrations/OnRobotDualQuickChanger';
import { calculateORDualChangerCommandFromOR3FG15Command } from '@sb/integrations/OnRobotDualQuickChanger';
import { Checkbox, RangeSpinner } from '@sb/ui/components';
import { margin } from '@sb/ui/styles';
import { convertMetersToMillimeterString } from '@sb/utilities';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';
import {
  useGuidedMode,
  useIsAnotherSessionRunningAdHocCommand,
  useRobotRoutineRunningState,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';

import type { OnRobot3FG15Configuration } from '../..';
import {
  calculateOR3FG15CommandFromState,
  calculateOR3FG15DiameterRange,
  getOR3FG15ConfigWithRoutineRunnerState,
} from '../..';
import { GripperStatus } from '../shared/GripperStatus';

import type { SingleValueModeProps } from './types';

export default function GripperDiameter({
  gripperState,
  routineRunnerArgs,
  activeDualChangeGripper,
}: SingleValueModeProps) {
  const [isGripChecked, setIsGripChecked] = useState<boolean>(false);

  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerArgs);

  const routineRunningState = useRobotRoutineRunningState(routineRunnerArgs);

  const isRoutineRunning = routineRunningState !== null;

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({
    ...routineRunnerArgs,
    shouldStopBeforeRun: false,
  });

  const fingerConfiguration: OnRobot3FG15Configuration =
    getOR3FG15ConfigWithRoutineRunnerState(gripperState);

  const isAnotherSessionMovingRobot =
    useIsAnotherSessionRunningAdHocCommand(routineRunnerArgs);

  const { min, max } = calculateOR3FG15DiameterRange({
    ...fingerConfiguration,
    actuationKind: 'grip',
  })[gripperState.gripKind];

  const onSpinnerHold = async (isPositive: boolean) => {
    await runAdHocCommand({
      onRunCommand: () => {
        const command = calculateOR3FG15CommandFromState(
          {
            ...gripperState,
            diameterMeters: isPositive ? max : min,
          },
          isGripChecked,
        );

        let parentCommand: OnRobotDualQuickChangerCommand | null = null;

        if (activeDualChangeGripper && command.kind === 'OnRobot3FG15Command') {
          parentCommand = calculateORDualChangerCommandFromOR3FG15Command(
            command,
            activeDualChangeGripper,
          );
        }

        return routineRunnerHandle.actuateDevice({
          command: parentCommand ?? command,
        });
      },
    });
  };

  return (
    <SectionContainer title="Diameter">
      <RangeSpinner
        primaryValue={gripperState.diameterMeters}
        min={min}
        max={max}
        isDisabled={isAnotherSessionMovingRobot || isRoutineRunning}
        isSpinPlusDisabled={gripperState.diameterMeters >= max}
        isSpinMinusDisabled={gripperState.diameterMeters <= min}
        onSpinnerHold={onSpinnerHold}
        onSpinnerRelease={stopGuidedMode}
        valueToString={convertMetersToMillimeterString}
      />

      <Checkbox
        checked={isGripChecked}
        onChange={(e) => {
          setIsGripChecked(e.target.checked);
        }}
        className={margin.top.medium}
      >
        Grip object with force
      </Checkbox>
      <GripperStatus status={gripperState} />
    </SectionContainer>
  );
}
